import $ from 'jquery';
import quickview from './quickview';
import basket from './basket';


export default function () {
  quickview();
  basket();
  
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  })

  $(document).on('click', 'a[href="#"]', function(e) {
    e.preventDefault();
  })

  // $(document).on('contextmenu', (e) => e.preventDefault());

}