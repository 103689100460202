import $ from 'jquery';
import { apiUrl } from './constants';

const $quickViewWrapper = $('.js-quickview-wrapper'),
      $quickViewContent = $('.js-quickview-content'),
      $quickViewOpenBtn = $('.js-quickview-open'),
      $quickViewCloseBtn = $('.js-quickview-close'),
      $quickViewBackdrop = $('.js-quickview-backdrop');
let productPromise;

export default function(){
  quickviewOpen();
  quickviewClose();
}


const quickviewClose = () => {
  $quickViewCloseBtn.on('click', (e) => {
    e.preventDefault();
    $('body').removeClass('no-scroll');
    $quickViewWrapper.addClass('d-none');
    $quickViewBackdrop.hide();
  })
}

const quickviewOpen = () => {

  $quickViewOpenBtn.on('click', (e) => {
    e.preventDefault();
    $quickViewWrapper.removeClass('d-none').addClass('active');    
    $quickViewBackdrop.show();
    const productSlug = e.currentTarget.dataset.slug;

    if(productSlug){
      getProductData(productSlug)

      productPromise.then( (data) => {
        renderProductData(data['product'], data['variants']);
        // $('body').addClass('no-scroll');
      })
      .catch( (err) => {
        console.log(err);
      })
    }
    
  })
}

const getProductData = async (slug) => {

  productPromise = new Promise( (resolve, reject) => {
    fetch(`${window.GLOBALS.baseLink}api/get-product-data/${slug}?d=${window.GLOBALS.DID}`)
    .then( response => response.json())
    .then( (response) => {
      resolve(response)
    });
  })

}

const renderProductData = (product, variants) => {
  $quickViewContent.html('');

  const productTemplate = `
    <div class="quickview-header">
      <div class="quickview-photo">
        <img src="${apiUrl}${product.ProductThumbFile}">
      </div>
      <div class="quickview-title">
        <h3>${product.ProductTitle}</h3>
      </div>
      <div class="quickview-price">
        <span>
          Ürün Fiyatı:
          <em>${product.ProductPrice}</em>
        </span>        
        <span>
          Pompa Fiyatı: 
          <em>${product.ProductPumpFee}</em>
        </span>
      </div>
      ${!variants.length ? `
      <div class="quickview-add-to-card">
        <a href="#" class="btn btn-rounded quick-add-to-card js-add-to-card" data-product-id="${product.ProductID}">
          Sepete Ekle
        </a>
      </div>
      ` : '' }
      <div class="quickview-description">
        ${product.ProductDescription}
      </div>
      ${variants.length ? `
        <div class="quickview-variants">
          <h2 class="quickview-seperator">
            <span>Ürünün Varyantları</span>
          </h2>
          <div class="js-quickview-product-variants"></div>
        </div>
      ` : '' }
    </div>
  `;

  $.each(variants, (i, variant) => {
    variant = variant[0];
    const template = `
      <div class="quickview-variant-item">
        <div class="quickview-header">
          <div class="quickview-photo">
            <img src="${apiUrl}${variant.ProductItemImage}">
          </div>
          <div class="quickview-title">
            <h3>${variant.ProductItemTitle}</h3>
          </div>
          <div class="quickview-price">
            <span>
              Ürün Fiyatı:
              <em>${variant.ProductItemPrice}</em>
            </span>  
          </div>
          <div class="quickview-add-to-card">
            <a href="#" class="btn btn-rounded quick-add-to-card js-add-to-card" data-product-type="variant" data-parent-product-id="${product.ProductID}" data-product-id="${variant.ProductItemID}">
              Sepete Ekle
            </a>
          </div>
          <div class="quickview-description">
            ${variant.ProductItemDescription}
          </div>
        </div>
        <div class="quickview-variant-seperator"><span></span></div>
      </div>
    `;
    
    setTimeout(() => {
      $(document).find('.js-quickview-product-variants').append(template)
    }, 500)
  })

  $quickViewContent.html(productTemplate);

}