import homepage from 'js/homepage/';
import checkout from 'js/checkout';
import success from 'js/success';

const globals = window.GLOBALS;

const route = {
  homepage,
  checkout,
  success
}[globals.page];

route && route();