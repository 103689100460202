export default function() {
  if(!('Notification' in window)){
    console.error('does not support')
  }else{
    Notification.requestPermission()
      .then((permission) => {
        checkPermission(permission);
      });
  }
}

const checkPermission = (permission) => {
  if(permission === 'granted') {
    const grantedOptions = {
      body: 'Yeni sipariş alındı, detay için tıklayın.',
      icon: 'https://www.conax.com.tr/Public/img/logo.webp'
    }
    showNotification('Sipariş Alarmı', grantedOptions);
  }
}

const showNotification = (title, options,) => {
  if(title === undefined || options === undefined){
    title = title ? title : 'Sipariş Alarmı';
    options = {
      body: 'Yeni sipariş alındı, detay için tıklayın.',
      icon: 'https://www.conax.com.tr/Public/img/logo.webp'
    }
  }
  const notify = new Notification(title, options);   
  notify.onclick = function(event) {
    event.preventDefault(); // prevent the browser from focusing the Notification's tab
    window.open('http://www.mozilla.org', '_blank');
  } 
}