import $ from 'jquery';
import '../../../node_modules/crypto-js/hmac-sha256';
import moment from 'moment';
const CryptoJS = require("crypto-js");

const windowWidth = $(window).width();
export const MOBILE_WIDTH = 768;
export const TABLET_WIDTH = 992;
export const isMobile = windowWidth < MOBILE_WIDTH;
export const isTablet = windowWidth < TABLET_WIDTH;
export const apiUrl = window.GLOBALS.baseLink;

export function scrollToElement(element, speed = 300){
  $('html, body').animate({
    scrollTop:$(element)
      .position().top
    }, speed);
}

export function newguid(element) {
 const $element = element;

 $element.val('xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { 
   let r = Math.random() * 16 | 0, 
   v = c == 'x' ? r : r & 0x3 | 0x8;
   return v.toString(16); 
  }));
  return $element;
}

export function hash(element, key, text) {
  const $element = $(element);
  const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, key);
  hmac.update(text);
  const hash = hmac.finalize();
  $element.val(hash);
  return $element;
}

export function utc(element) {
  return moment.utc(element.val(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
}