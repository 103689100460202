import $ from 'jquery';
import 'slick-carousel'
import '../../../node_modules/lightbox2/dist/js/lightbox-plus-jquery';
const WOW = require('wow.js');
import urls from '../urls';
import { isMobile } from '../common/constants';

export default function () {
  new WOW().init();
  saveContactForm();

  // homeslider
  $('.js-section-slider-big').slick({
    dots: true,
    arrows: false
  });

  if(isMobile){
    $('.js-mobile-gallery-slider').slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      nextArrow: '<i class="fa fa-arrow-right slick-arrow arrow-next"></i>',
      prevArrow: '<i class="fa fa-arrow-left slick-arrow"></i>',
      autoplay: true,
      autoplaySpeed: 3000,
    });
  }

  // Go to Section
  $('.js-go-section').on('click', function(e){
    const lastPath = window.location.pathname.split('/').pop()
    const target = $(this).data('go-to');
    if(lastPath){
      localStorage.setItem('target', target)
      window.location.href = `./${target}`;
    }else{
      e.preventDefault();
    }

    if(target){
      $('html, body').animate({
        scrollTop: $(`[data-target="${target}"]`)
        .offset().top - 25
      }, 1000);
    }
  });

  // Sticky Header
  $(window).scroll(function(){
    const sticky = $('nav.navbar'),
          scroll = $(window).scrollTop();
  
    if (scroll >= 100) sticky.addClass('sticky');
    else sticky.removeClass('sticky');
  });

  if(window.location.hash){
    $('html, body').animate({
      scrollTop: $(`[data-target="${window.location.hash}"]`)
      .offset().top - 25
    }, 1000);
    localStorage.removeItem('target');
  }

}

const saveContactForm = () => {
  $('.js-save-contact-form').on('submit', (e) => {
    e.preventDefault();
    const formData = $(e.target).serialize();

    $.ajax({
      url: urls.saveContactForm,
      type: "POST",
      data: formData,
      dataType: 'json',
      success: function(s){
        console.log(s);
        // toastr[s.Type](s.Message, s.Title)
        // window.setTimeout( function(){
        //   window.location = s.Redirect
        // }, 1500 );
      }
    });
 
  })
}
