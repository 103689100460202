import $ from 'jquery';
import urls from '../urls';
import Swal from 'sweetalert2'

export default function(){

  $(document).ready(function () {
    /* vp_h will hold the height of the browser window */
    var vp_h = $(window).height();
    /* b_g will hold the height of the html body */
    var b_g = $('body').height();
    /* If the body height is lower than window */
    if (b_g < vp_h) {
        /* Set the footer css -> position: absolute; */
        $('footer').css({
          "position": "absolute",
          "left": 0,
          "right": 0,
          "bottom": 0
        });
    }
  });

  $(document).on('click', '.js-add-to-card', function(e){
    e.preventDefault();
    const productID = $(this).data('product-id');
    const parentProductID = $(this).data('parent-product-id') ? $(this).data('parent-product-id') : 0;
    const productType = $(this).data('product-type') ? $(this).data('product-type') : 'product';

    if(productID){
      addToBasket(productID, parentProductID, productType);
    }
  })
  
  $(document).on('change', '.js-update-qty', function(e){
    e.preventDefault();
    const productID = $(this).closest('tr').data('product-id');
    const productQty = $(this).val();
    if(productID){
      updateBasketProductQty(productID, productQty);
    }
  })

  $(document).on('change', '.js-update-pump', function(e) {
    e.preventDefault();
    const value = $(this).val();
    const pumpPrice = $(this).data('pump-price');
    const productID = $(this).closest('tr').data('product-id');
    
    if(value == "-1") {
      Swal.fire(
        'Hata',
        'Lütfen seçim yapın',
        'error'
      )
    }

    if(value == 1) {
      updateBasketPumpPrice(pumpPrice, productID, 'plus');
    }

    if(value == 0) {
      updateBasketPumpPrice(pumpPrice, productID, 'minus');
    }
  })
  
  $(document).on('click', '.js-remove-product', function(e){
    e.preventDefault();
    const productName = $(this).data('product-title');
    if(productName){
      removeToBasket(productName);
    }
  })
}

const addToBasket = (productid, parentid, producttype) => {
  const requestData = {
    ProcessType: 'add',
    ProductID: productid,
    ProductParentID: parentid,
    ProductType: producttype
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        Swal.fire({
          title: 'Tebrikler',
          text: response.Message,
          icon: 'success',
          timer: 2000,
          confirmButtonText: 'Tamam'
        });

        $('.js-basket-count').text(`(${response.BasketCount})`)
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error',
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}

const updateBasketProductQty = (productid, productQty) => {
  const requestData = {
    ProcessType: 'update',
    ProductID: productid,
    ProductQty: productQty
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        if(response.Redirect){
          window.location.href = response.Redirect;
        }
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}

const updateBasketPumpPrice = (pumpprice, productid, type = null) => {
  let requestData = {
    "ProcessType": 'updatePump',
    "PumpType": type,
    "ProductID": productid,
    "PumpPrice": pumpprice
  }

 if(type && pumpprice && productid) {
  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      console.log(response);
      if(response.IsSuccess){

        if(response.Redirect){
          window.location.href = response.Redirect;
        }
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
 }
}

const removeToBasket = (product) => {
  const requestData = {
    ProcessType: 'remove',
    ProductID: product
  };

  $.ajax({
    url: urls.basket,
    method: 'POST',
    dataType: 'json',
    data: requestData,
    success: function(response) {
      if(response.IsSuccess){
        Swal.fire({
          title: 'Tebrikler',
          text: response.Message,
          icon: 'success',
          timer: 2000,
          confirmButtonText: 'Tamam'
        });
        
        if(response.Redirect){
          setTimeout(() => {
            window.location.href = response.Redirect
          }, 1000)
        }
      }else{
        Swal.fire(
          'Hata',
          response.Message,
          'error'
        )
      }
    },
    error: function(err) {
      console.log(err)
    }
  })
}
