import '../less/index.less'

import $ from 'jquery'
window.jQuery = $
window.$ = $

import 'js/appRouter'
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import '../../node_modules/jquery-validation/dist/localization/messages_tr';
import '../../node_modules/jquery-validation/dist/jquery.validate';
import Swal from 'sweetalert2'

import common from 'js/common/index';

export default function(){
  Swal.mixin({ 
    timer: 2000,
    showConfirmButton: false
  })
}
(function () {
  common();

  if($.validator && $.validator.messages){

    $.validator.setDefaults({
      highlight: function (element) {
        $(element).addClass('border-bottom border-danger');
      },
      unhighlight: function (element) {
        $(element).removeClass('border-bottom border-danger');
      },
      errorPlacement: function (error, element) {
        if($(element).attr('type') == 'checkbox'){
          $(element).closest('label').find('.js-error-msg').html(error)
        }
        return true;
      }
    });

    var random = Math.random(0,20)
  
    jQuery.extend(jQuery.validator.messages, {
        required: "Bu alanın doldurulması zorunludur.",
        remote: "Lütfen bu alanı düzeltin.",
        email: "Lütfen geçerli bir e-posta adresi giriniz.",
        url: "Lütfen geçerli bir web adresi (URL) giriniz.",
        date: "Lütfen geçerli bir tarih giriniz.",
        dateISO: "Lütfen geçerli bir tarih giriniz(ISO formatında)",
        number: "Lütfen geçerli bir sayı giriniz.",
        digits: "Lütfen sadece sayısal karakterler giriniz.",
        creditcard: "Lütfen geçerli bir kredi kartı giriniz.",
        equalTo: "Lütfen aynı değeri tekrar giriniz.",
        extension: "Lütfen geçerli uzantıya sahip bir değer giriniz.",
        maxlength: $.validator.format("Lütfen en fazla {0} karakter uzunluğunda bir değer giriniz."),
        minlength: $.validator.format("Lütfen en az {0} karakter uzunluğunda bir değer giriniz."),
        rangelength: $.validator.format("Lütfen en az {0} ve en fazla {1} uzunluğunda bir değer giriniz."),
        range: $.validator.format("Lütfen {0} ile {1} arasında bir değer giriniz."),
        max: $.validator.format("Lütfen {0} değerine eşit ya da daha küçük bir değer giriniz."),
        min: $.validator.format("Lütfen {0} değerine eşit ya da daha büyük bir değer giriniz."),
        require_from_group: $.validator.format("Lütfen bu alanların en az {0} tanesini doldurunuz.")
    });
  
    $.validator.methods.number = function (value, element) {
        return this.optional(element) || /-?(?:\d+|\d{1,3}(?:[\s\.,]\d{3})+)(?:[\.,]\d+)?$/.test(value);
    }
  }
})();
