import $ from 'jquery';
import urls from '../urls';
import Swal from 'sweetalert2'
import { utc, newguid, hash, scrollToElement } from '../common/constants'

const mask = require('../../../node_modules/jquery-mask-plugin/dist/jquery.mask');
import 'jquery-mask-plugin'

export default function() {
  fillTheInputs();
  isSameAddressControl();
  $('[data-mask=phone]').mask('0(000) 000-0000');
}

const fillTheInputs = () => {
  $('[data-mask=phone]').unmask();
}

const isSameAddressControl = () => {
  $('.js-check-same-address').on('change', (e) => {
    const $this = $(e.currentTarget);
    $('.js-shipping-form').addClass('d-none');
    removeDeliveryForm();
    if(!$this.is(':checked')){
      $('.js-shipping-form').removeClass('d-none');
      scrollToElement($('.js-shipping-form'), )
      appendDeliveryForm();
    }
  })
}

const appendDeliveryForm = () => {
  $('.js-delivery-form-content').append(`
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="form-group-wrapper">
          <label for="">Ad</label>
          <div class="form-group-input-wrapper">
            <input type="text" class="inp" name="DELIVERY.FNAME" placeholder="Ad"
              data-target="DELIVERY.FNAME" required>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group-wrapper">
          <label for="">Soyad</label>
          <div class="form-group-input-wrapper">
            <input type="text" class="inp" name="DELIVERY.LNAME" placeholder="Soyad"
              data-target="DELIVERY.LNAME" required>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12 col-12">
        <div class="form-group-wrapper">
          <label for="DADRESS">Adres</label>
          <div class="form-group-input-wrapper">
            <input type="text" class="inp" name="DELIVERY.ADDRESS" placeholder="Ad"
              data-target="DELIVERY.ADDRESS" required>
          </div>
        </div>
      </div>
    </div>
  `)
}

const removeDeliveryForm = () => {
  $('.js-delivery-form-content').html('');
}